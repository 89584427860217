<template>
  <div class="form-group mb-3">
    <div class="mb-2" v-show="!data.files.length">
      <label v-bind:for="componentId + '-file'" class="custom-label">{{
        title
      }}</label>
      <input
        type="file"
        ref="fileInput"
        v-bind:id="componentId + '-file'"
        class="form-control d-none"
        v-bind:accept="acceptedMimeTypes"
        v-on:change="selectFile()"
      />
      <div ref="dropzone" class="dropzone-area" v-show="!data.files.length">
        <i v-bind:class="icon"></i>
        <span class="dropdonze-header text-muted">{{ dragText }}</span>
        <span class="or-divider">{{ orText }}</span>
        <div class="text-center">
          <span
            class="btn btn-outline-primary"
            v-on:click="$refs.fileInput.click()"
            >{{ buttonText }}</span
          >
        </div>
      </div>
    </div>
    <div class="text-danger font-14 my-2" v-show="!data.files.length">
      {{ sizeLimitMessage }}
    </div>
    <div v-if="data.files[0] && data.files[0].fileName" class="uploaded-card">
      <div class="uploaded-media card-body">
        <i class="fas fa-file-excel fa-5x text-success file-icon"></i>
        <div
          class="row align-items-center"
          v-bind:class="{ 'mb-2': metaVisible }"
        >
          <div class="col text-truncate ml-4">
            <span class="d-block">{{ data.files[0].fileName }}</span>
            <span class="text-secondary">{{ data.files[0].fileSize }}</span>
          </div>
          <div class="col-auto">
            <span
              class="btn btn-outline-danger font-14"
              v-on:click="removeFile()"
            >
              <i class="fas fa-trash"></i>
            </span>
          </div>
        </div>
        <div v-if="metaVisible">
          <div class="col p-0">
            <input
              type="text"
              class="font-14 form-control"
              placeholder="File Alt"
              v-model="data.files[0].metaAlt"
            />
          </div>
          <div class="col-12 mt-2 col-md p-0">
            <input
              type="text"
              class="font-14 form-control"
              placeholder="File Title"
              v-model="data.files[0].metaTitle"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Single",
  props: {
    data: {
      files: [],
      filesData: null,
    },
    title: {
      default: "Dosya Yükle",
    },
    icon: {
      default: "fas fa-cloud-upload-alt fa-3x text-secondary",
    },
    buttonText: {
      default: "Dosya Seç",
    },
    dragText: {
      default: "Dosya Sürükle & Bırak",
    },
    orText: {
      default: "veya",
    },
    sizeLimit: {
      default: "1024",
    },
    sizeLimitMessage: {
      default: "En fazla 1024 KB boyutunda dosya yükleyebilirsiniz.",
    },
    metaVisible: {
      default: "true",
    },
    acceptedMimeTypes: {
      default: ".xlsx",
    },
  },
  model: {
    prop: "data",
  },
  methods: {
    clearFile() {
      this.data.files = [];
      this.data.filesData = null;
    },
    newFile() {
      this.clearFile();

      this.data.files.push({
        metaAlt: "",
        metaTitle: "",
        fileName: "",
        fileSize: "",
        mimeType: "",
      });

      this.data.filesData = new FormData();
    },
    selectFile() {
      const input = this.$refs.fileInput;

      if (input.files.length) {
        this.createFile(input.files[0]);
      }
    },
    createFile(file) {
      this.newFile();

      if (file.size <= parseInt(this.sizeLimit) * 1024) {
        this.data.files[0].fileName = file.name;
        this.data.files[0].fileSize = helper.formatSize(file.size);
        this.data.files[0].mimeType = file.type;
        this.data.files[0].file = file;
        // this.data.filesData.set(file.name, file);
      } else {
        this.removeFile(
          "Yüklemeye çalıştığınız dosya boyutu yüksek!\n" +
            this.sizeLimitMessage
        );
      }
    },
    clearInput() {
      this.$refs.fileInput.value = "";
    },
    removeFile(message) {
      this.clearInput();
      this.clearFile();

      if (message) {
        this.$swal({
          title: "Hata",
          text: message,
          icon: "error",
        });
      }
    },
    dropzoneHighlight(unhighlight) {
      const dropzone = this.$refs.dropzone;

      if (!unhighlight) {
        dropzone.classList.add("highlight");
      } else {
        dropzone.classList.remove("highlight");
      }
    },
    onDragEnter(e) {
      e.preventDefault();
      e.stopPropagation();

      this.dropzoneHighlight();
    },
    onDragLeave(e) {
      e.preventDefault();
      e.stopPropagation();

      if (this.lastDragLeave + 1000 < Date.now()) {
        this.lastDragLeave = Date.now();
        this.dropzoneHighlight(true);
      }
    },
    onDragOver(e) {
      e.preventDefault();
      e.stopPropagation();

      this.dropzoneHighlight();
    },
    onDrop(e) {
      e.preventDefault();
      e.stopPropagation();

      this.dropzoneHighlight(true);

      if (e.dataTransfer.files && e.dataTransfer.files.length) {
        this.createFile(e.dataTransfer.files[0]);
      }
    },
  },
  mounted() {
    this.lastDragLeave = Date.now();

    this.clearFile();

    const dropzone = this.$refs.dropzone;
    dropzone.addEventListener("dragenter", this.onDragEnter, false);
    dropzone.addEventListener("dragleave", this.onDragLeave, false);
    dropzone.addEventListener("dragover", this.onDragOver, false);
    dropzone.addEventListener("drop", this.onDrop, false);
  },
  data() {
    return {
      componentId:
        "wefileuploader-single-" + helper.getRandomInteger(1, 999999),
      lastDragLeave: null,
    };
  },
};
</script>

<style lang="scss" scoped>
/* DROPDONZE AREA */
.dropzone-area {
  position: relative;
  padding: 1rem;
  border-radius: 6px;
  border: 2px dashed #1e90ff;
  background-color: #1e8fff15;
}

.dropzone-area.highlight {
  border-color: #2ed573;
  background-color: #2ed5742f;
}

.dropzone-area span,
.dropzone-area a {
  font-size: 14px;
}

.or-divider,
.dropdonze-header,
.dropzone-area > i {
  display: block;
  margin-bottom: 0.5rem;
  text-align: center;
}
</style>
